import React from "react";
import * as firebase from "firebase/app";
import { Menu, Icon } from "semantic-ui-react";

// TODO: REPLACE ME WITH BOOTSTRAP NAV BAR?

const AppHeader = ({ currUser }) => {
  return (
    <div>
      <Menu pointing secondary>
        <Menu.Item
          name="home"
          active={window.location.href.indexOf("/home") > -1}
          onClick={() => (window.location.href = "/home")}
        />

        <Menu.Item
          name="Set a NickName"
          active={window.location.href.indexOf("/nickname") > -1}
          onClick={() => (window.location.href = "/nickname")}
        />

        <Menu.Item
          name="Sign out"
          onClick={() => {
            firebase.auth().signOut();
          }}
          position="right"
        />
      </Menu>
    </div>
  );
};

export default AppHeader;
