import React from "react";
import { Button, Form } from "semantic-ui-react";

import { saveToDatabase } from "./hooks/database";
import { v1 as uuid } from "uuid";

const CreateChat = ({ currentUser, users }) => {
  const createNewChatRoom = () => {
    const chatRoomID = uuid();
    saveToDatabase(`/${currentUser.uid}/chats/${chatRoomID}`, chatName);
    saveToDatabase(`/chats/${chatRoomID}/messages`, {});
  };

  const [chatName, setChatName] = React.useState("");

  return (
    <div className="panel">
      <h3>Create a Chat</h3>
      <Form>
        <Form.Field>
          <label>Chat name</label>
          <input
            onChange={(event) => setChatName(event.target.value)}
            value={chatName}
          />
        </Form.Field>
        <Button primary onClick={() => createNewChatRoom()}>
          Start conversation
        </Button>
      </Form>
    </div>
  );
};

export default CreateChat;
