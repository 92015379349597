import React from "react";
import MuiAvatar from "@material-ui/core/Avatar";
import moment from "moment-timezone";

const ChatRow = ({ message, currUserID }) => {
  var initials = message.senderNickName.match(/\b\w/g) || [];
  initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();

  const formatInfo = () => {
    return `by ${message.senderNickName} at 
      ${moment(message.created).from(moment())}`;
  };

  const outgoing = message.senderID !== currUserID;

  return (
    <div className="my-1 d-flex">
      <MuiAvatar
        className="m-2"
        alt={initials}
        style={{ width: 30, height: 30 }}
      >
        {initials}
      </MuiAvatar>
      <div>
        <div className={`${outgoing ? "bg-info" : "bg-warning"} rounded p-1`}>
          {message.body}
        </div>
        <small className="text-muted">{formatInfo()}</small>
      </div>
    </div>
  );
};

export default ChatRow;
