import { saveToDatabase } from "../../chat_room/hooks/database";

const useAuth = () => {
  const changeNickname = async (
    userId,
    displayName,
    onSuccess = () => null
  ) => {
    await saveToDatabase(`/users/${userId}`, displayName);
    onSuccess && onSuccess();
  };

  return { changeNickname };
};

export default useAuth;
