import React from "react";
import { getFromDatabase, saveToDatabase } from "./database";
import { v1 as uuid } from "uuid";

import fetchChatRooms from "./fetchChatRooms";

const useMsgClient = (currUserID) => {
  const [allMsg, setAllMsg] = React.useState([]);

  /* trying to get the chatName by getting all of the ActiveChats
  the ActiveChats are also being undefined*/

  const { myActiveChats } = fetchChatRooms(currUserID);

  let chatRoomID = window.location.pathname.substring(6);
  let chatName = myActiveChats[chatRoomID];
  // debugger;

  const getUserName = (userID) => {
    var name = "WRONG!";
    getFromDatabase(`/users/`, (res) => {
      Object.keys(res).forEach((e) => {
        if (e === userID) {
          name = res[e];
        }
      });
    });
    return name;
  };

  let senderUserName = getUserName(currUserID);

  React.useEffect(() => {
    chatRoomID = window.location.pathname.substring(6);
    getFromDatabase(`/chats/${chatRoomID}/messages`, (res) => {
      setAllMsg(Object.values(res));
    });
  }, []);

  const sendMsg = (body) => {
    const msgID = uuid();
    saveToDatabase(`/chats/${chatRoomID}/messages/${msgID}`, {
      body,
      senderID: currUserID,
      senderNickName: senderUserName,
      created: new Date().toISOString(),
    });
  };

  /** invite user with recipitentID to the current chat room. */
  const inviteChat = async (recipientID) => {
    if (chatName) {
      await saveToDatabase(`/${recipientID}/chats/${chatRoomID}`, chatName);
      debugger;
    } else {
      console.log("AN ERROR HAS OCCURR");
    }
  };

  // debugger;
  // console.log(chatID);
  // console.log(currMsg);
  // console.log("My chat Name " + chatName);
  // console.log(sendMsg);
  // console.log(setCurrMsg);
  // debugger;

  return { chatRoomID, allMsg, sendMsg, inviteChat };
};

export default useMsgClient;
