import React, { useEffect } from "react";

import ChatWindow from "./ChatWindow";
import msgClient from "./hooks/msgClient";
import ChatThread from "./chatThread";
import ChatHeader from "../layout/ChatHeader";
const ChatPage = ({ currentUser }) => {
  const { chatRoomID, allMsg, sendMsg, inviteChat } = msgClient(
    currentUser.uid
  );

  return (
    <div>
      {chatRoomID && (
        <>
          <ChatHeader
            chatRoomID={chatRoomID}
            inviteChat={inviteChat}
            currentUser={currentUser}
          />
          <ChatThread messages={allMsg} currUserID={currentUser.uid} />
          <ChatWindow sendMessage={sendMsg} messages={allMsg} />
        </>
      )}
    </div>
  );
};

export default ChatPage;
