import React, { useEffect } from "react";
import * as firebase from "firebase/app";

import useAuth from "./hooks/useAuth";

const SetUserName = () => {
  const [nickName, setNickName] = React.useState("");
  const [currentUser, setCurrentUser] = React.useState(undefined);

  useEffect(() => {
    const user = firebase.auth().currentUser;
    setCurrentUser(user);
  }, []);

  const { changeNickname } = useAuth();

  return (
    <div className="panel">
      <input onChange={(event) => setNickName(event.target.value)} />
      <button
        onClick={() =>
          changeNickname(
            currentUser.uid,
            nickName,
            () => (window.location.href = "/home")
          )
        }
        disabled={!nickName}
      >
        Set Nick Name
      </button>
    </div>
  );
};

export default SetUserName;
