import React from "react";
import { Button, Card, Container, Row, Col } from "react-bootstrap";

import fetchChatRooms from "./hooks/fetchChatRooms";

const chatRoom = ({ userID }) => {
  const { myActiveChats } = fetchChatRooms(userID);
  return (
    myActiveChats && (
      <Container>
        <div>
          <h3>Select an Active Chat</h3>
          <Row>
            {Object.keys(myActiveChats).map((chat) => (
              <Col xs={6}>
                <Card className="p-3">
                  {myActiveChats[chat]}
                  <Button
                    variant="info"
                    className="h2 font-weight-bold"
                    onClick={() => {
                      window.location.href = `chat/${chat}`;
                    }}
                  >
                    Join
                  </Button>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    )
  );
};

export default chatRoom;
