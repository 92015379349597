var firebaseConfig = {
  apiKey: "AIzaSyCzqjrxfwYDY7IZWk4QHejpGXcmY_rVv44",
  authDomain: "reactmsgapp.firebaseapp.com",
  databaseURL: "https://reactmsgapp.firebaseio.com",
  projectId: "reactmsgapp",
  storageBucket: "reactmsgapp.appspot.com",
  messagingSenderId: "967564518512",
  appId: "1:967564518512:web:3597f9baefda6c9a1280b5",
  measurementId: "G-C2QPML2W52",
};

export default firebaseConfig;
