import React from "react";

import { getFromDatabase } from "./database";

const useFetchChatRooms = (userID) => {
  const [myActiveChats, setMyActiveChats] = React.useState([]);

  React.useEffect(() => {
    showAllChatRooms();
  }, [userID]);

  const showAllChatRooms = () => {
    getFromDatabase(`/${userID}/chats`, (res) => {
      setMyActiveChats(res);
    });
  };

return { myActiveChats, showAllChatRooms };
};

export default useFetchChatRooms;
