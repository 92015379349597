import { database } from "firebase";

export const getFromDatabase = (dbString, callback) => {
  const ref = database().ref(dbString);
  ref.on("value", (snapshot) => {
    if (snapshot.val()) {
      callback(snapshot.val());
    }
  });
  return true;
};

export const saveToDatabase = async (dbString, val) => {
  await database().ref(dbString).set(val);
};
