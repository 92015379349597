import React from "react";
import { getFromDatabase } from "./database";

const useUsers = () => {
  const [users, setUsers] = React.useState(undefined);

  React.useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    await getFromDatabase(`/users/`, (res) => {
      setUsers(res);
    });
  };

  return { users, fetchUsers };
};

export default useUsers;
