import React from "react";

import ChatRow from "./ChatRow";

const ChatThread = ({ messages = [], currUserID }) => {
  return (
    <div style={{ maxHeight: 400, overflow: "auto" }}>
      <div className="messages">
        {messages.map((message) => (
          <ChatRow message={message} currUserID={currUserID} />
        ))}
      </div>
    </div>
  );
};

export default ChatThread;
