import React from "react";
import { DropdownButton, Row, Col, Button, Dropdown } from "react-bootstrap";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useUsers from "../chat_room/hooks/users";

const ChatHeader = ({ chatRoomID, inviteChat, currentUser }) => {

  const { users } = useUsers();
  let myNickName = users ? users[currentUser.uid] : undefined;

  const filterUsersFunc = () => {
    if (currentUser && users) {
      const filtered = Object.keys(users)
        .filter((key) => key !== currentUser.uid)
        .reduce((obj, key) => {
          obj[key] = users[key];
          return obj;
        }, {});
      return filtered;
    }
    return {};
  };

  let filteredUsers = filterUsersFunc();

  return (
    <div className="chat-header">
      {myNickName && (
        <Row>
          <Col>
            <Button
              variant="link"
              size="sm"
              onClick={() => (window.location.href = "/home")}
            >
              <ArrowBackIcon />
              Back
            </Button>
          </Col>

          <Col>
            <DropdownButton
              id="drop-down-basic"
              title="Invite"
              size="sm"
              variant="link"
            >
              {Object.entries(filteredUsers).map((user) => (
                <Dropdown.Item
                  value={user[0]}
                  key={user[0]}
                  onClick={() => inviteChat(user[0])}
                >
                  {user[1]}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ChatHeader;
