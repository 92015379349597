import React from "react";
import { Container, Col, Button, Form, Row } from "react-bootstrap";

const ChatWindow = ({ messages = [], sendMessage }) => {
  const [messageBody, setMessageBody] = React.useState("");

  const handleEnter = (event) => {
    if (messageBody !== "") {
      if (event.shiftKey) {
        if (event.keyCode === 13) {
          const addLine = messageBody + "\n ";
          setMessageBody(addLine);
        }
      } else if (event.keyCode === 13) {
        sendMessage(messageBody);
        setMessageBody("");
      }
    }
  };

  return (
    <div className="panel">
      <div className="ui hidden divider"></div>

      <Container>
        <Row>
          <Col>
            <Form>
              <Form.Group controlId="messageBox">
                <Form.Control
                  as="textarea"
                  placeholder="Type a message"
                  value={messageBody}
                  onChange={(e) => setMessageBody(e.target.value)}
                  placeholder="Have something to say?"
                  onKeyDown={handleEnter}
                />
              </Form.Group>
            </Form>
          </Col>

          <Col>
            <Button
              variant="primary"
              type="submit"
              onClick={() => {
                sendMessage(messageBody);
                setMessageBody("");
              }}
            >
              Send
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ChatWindow;
