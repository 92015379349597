import React from "react";
import ReactDOM from "react-dom";
import "semantic-ui-css/semantic.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

import "./index.css";
import Signin from "./authentication/SignIn";

// firebase.initializeApp(firebaseConfig);
ReactDOM.render(<Signin />, document.getElementById("root"));
