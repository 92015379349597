import React from "react";
import { Container, Divider } from "semantic-ui-react";

import CreateChat from "./CreateChat";
import ChatRoom from "./chatRoom";
import useUsers from "./hooks/users";
import { getFromDatabase } from "./hooks/database";

const HomePage = ({ currentUser }) => {
  const { users } = useUsers();

  return (
    <div className="homepage">
      {currentUser.uid && (
        <>
          <Container textAlign="center">
            {users != null && (
              <CreateChat currentUser={currentUser} users={users} />
            )}
          </Container>
          <Divider horizontal>Or</Divider>
          <Container textAlign="center">
            <ChatRoom userID={currentUser.uid} />
          </Container>
        </>
      )}
    </div>
  );
};

export default HomePage;
