import React from "react";
import { Container, Alert } from "react-bootstrap";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import AppHeader from "./layout/AppHeader";
import SetUserName from "./authentication/SetUserName";
import HomePage from "./chat_room/HomePage";
import ChatPage from "./chat_room/ChatPage";
import useAuth from "./authentication/hooks/useAuth";
const App = ({ currUser }) => {
  const { changeNickname } = useAuth();
  console.log(currUser);

  return (
    <div onMouseMove={() => changeNickname(currUser.uid, currUser.displayName)}>
      <Container>
        <AppHeader currUser={currUser} />
        <Router>
          <Switch>
            <Route path="/nickname">
              <SetUserName />
            </Route>
            <Route path="/home">
              <HomePage currentUser={currUser} />
            </Route>
            <Route path="/chat">
              <ChatPage currentUser={currUser} />
            </Route>
          </Switch>
        </Router>
      </Container>
    </div>
  );
};
export default App;
